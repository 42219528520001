import { LastOrder } from 'domains/cart';
import style from './style.module.css';
import { useMediaQuery } from 'ui-kit/hooks';
import { GET_ENUMS, GET_PARAMS, MOBILE_MENU_WIDTH } from 'const';
import { OrderStatus, OrderType } from 'models';
import clsx from 'clsx';
import moment from 'moment';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'ui-kit/images';
import { useNavigate } from 'react-router-dom';
import { usePrepareLink } from 'hooks/router';
import { FEImageComponent } from 'ui-kit/components';
import { FEDateUtils } from 'utils';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const formatTimeInterval = (timeInterval: moment.Moment, timeError: number) => {
  if (timeError === 0) return timeInterval.format('HH:mm');

  const first = timeInterval.subtract(timeError, 'minutes').format('HH:mm');
  const second = timeInterval.add(timeError * 2, 'minutes').format('HH:mm');
  return `${first} - ${second}`;
};

const getTime = (
  dateTime: string,
  timeError: number,
  status: OrderStatus,
  toUTC?: boolean,
) => {
  const momentedDateTime = moment(dateTime);
  if (toUTC) FEDateUtils.addUtcOffset(momentedDateTime);
  if (!Boolean(momentedDateTime.isSame(moment(), 'day'))) {
    return `${momentedDateTime.format('DD.MM.YYYY')}, ${formatTimeInterval(
      momentedDateTime,
      timeError,
    )}`;
  } else {
    return `${
      status !== 'CANCELLED' && status !== 'DONE'
        ? i18next.t('banners.lastOrder.todayLabel')
        : momentedDateTime.format('DD.MM.YYYY')
    }, ${formatTimeInterval(momentedDateTime, timeError)}`;
  }
};

const LastOrderBanner = ({ lastOrder }: { lastOrder: LastOrder | null }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_MENU_WIDTH}px)`);

  const navigate = useNavigate();

  const orderLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.orders,
    },
    pushToQuery: {
      [GET_PARAMS.orderId]: lastOrder?.order.id || '',
    },
  });

  if (lastOrder === null) return null;

  const {
    order: {
      type,
      number,
      status,
      dishes,
      constructors,
      receiptInformation: { deliveryTimeErrorInMinutes, dateTimeTo, urgent },
    },
  } = lastOrder;

  const galleryArray = [
    ...dishes.map((dish) => dish.product.imageId),
    ...constructors.map((constructor) => constructor.product.imageId),
  ];

  const showHiddenLength = galleryArray.length > 4;
  const hiddenCartLength = galleryArray.length - 3;

  const statuses: Record<OrderType, Record<OrderStatus, string>> = {
    COURIER: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: t('orders.statuses.ON_WAY'),
      WAIT: t('orders.statuses.WAIT_COURIER'),
    },
    GO_TO_PLACE: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: '',
      WAIT: t('orders.statuses.WAIT_GO_TO_PLACE'),
    },
    IN_PLACE: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: '',
      WAIT: t('orders.statuses.WAIT_GO_TO_PLACE'),
    },
  };

  const getStatus = (
    type: OrderType,
    status: OrderStatus,
    dateTimeTo: string,
  ) => {
    if (status !== 'CANCELLED' && moment(dateTimeTo).isAfter(moment(), 'day')) {
      return t('orders.statuses.PREORDER');
    }
    return statuses[type][status];
  };

  return (
    <div
      className={style.root}
      onClick={() => navigate(orderLink, { replace: true })}
    >
      <div className={style.container}>
        {isDesktop ? (
          <>
            <div className={style.header}>
              <p className={isDesktop ? 'title' : 'headline'}>
                {getStatus(type, status, dateTimeTo)}
              </p>
              <p
                className={clsx(
                  isDesktop ? 'text-1' : 'caption-1',
                  style.orderNumber,
                )}
              >{`№${number}`}</p>
            </div>
            <div className={style.timeContainer}>
              <p
                className={clsx(
                  isDesktop ? 'text-1' : 'caption-1',
                  style.timeCaption,
                )}
              >
                {type === 'COURIER'
                  ? t('orders.statusesLabels.courier.rest')
                  : t('orders.statusesLabels.goToPlace.rest')}
              </p>
              <p className={clsx('tetx-1', style.timeEl)}>
                {urgent
                  ? getTime(
                      dateTimeTo,
                      deliveryTimeErrorInMinutes,
                      status,
                      true,
                    )
                  : getTime(
                      dateTimeTo,
                      deliveryTimeErrorInMinutes,
                      status,
                      true,
                    )}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={style.mobileHeaderContainer}>
              <p className={clsx('tetx-1', style.statusLabel)}>
                {getStatus(type, status, dateTimeTo)}
              </p>
              <p className={style.mobileNumber}>{`№${number}`}</p>
            </div>

            <div className={style.info}>
              <div className={style.timeContainer}>
                <p className={clsx('caption-1', style.timeCaption)}>
                  {t('banners.lastOrder.expectedTimeLabel')}
                </p>
                <p className={clsx('tetx-1', style.timeEl)}>
                  {urgent
                    ? getTime(
                        dateTimeTo,
                        deliveryTimeErrorInMinutes,
                        status,
                        true,
                      )
                    : getTime(
                        dateTimeTo,
                        deliveryTimeErrorInMinutes,
                        status,
                        true,
                      )}
                </p>
              </div>
            </div>
          </>
        )}
        <div className={style.galery}>
          {!showHiddenLength ? (
            galleryArray.map((imageId, i) => (
              <div className={style.galleryCard} key={imageId}>
                <FEImageComponent
                  className={style.galleryImage}
                  image={{ imageId: imageId || '' }}
                  altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
                />
              </div>
            ))
          ) : (
            <>
              {galleryArray.slice(0, 3).map((imageId, i) => (
                <div className={style.galleryCard} key={imageId}>
                  <FEImageComponent
                    className={style.galleryImage}
                    image={{ imageId: imageId || '' }}
                    altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
                  />
                </div>
              ))}
              <div className={style.hiddenCard}>
                <p
                  className={clsx('title', style.hiddenValue)}
                >{`+${hiddenCartLength}`}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LastOrderBanner;
